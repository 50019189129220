





























































































































































































import auth from '@/mixins/auth';
import { Franchise, Warehouse } from '@/models/franchise';
import ErrorAlert from '../common/ErrorAlert.vue';

import { ErrorManager } from '@/models/error';
import { mapActions, mapState } from 'vuex';

import FranchiseFormDialog from './FranchiseFormDialog.vue';
import StaffFranchiseManagerMobile from './StaffFranchiseManagerMobile.vue';
import { searchItems } from '@/utils/functions';
import { Account } from '@/models/account';

export default auth.extend({
  components: {
    ErrorAlert,
    FranchiseFormDialog,
    StaffFranchiseManagerMobile
  },
  name: 'StaffFranchiseManager',
  data() {
    return {
      loading: false,
      dialog: false,
      deleteFranchiseDialog: false,
      snackbar: false,

      errorMessage: '',
      detailErrorMessage: '',
      search: '',
      snackbarEmail: '',

      chosenFranchise: new Franchise(),

      page: 1,
      pageCount: 0,
      itemsPerPage: 10,
      headers: [
        {
          text: '',
          sortable: false,
          align: 'start',
          width: '20px',
          value: 'data-table-space'
        },
        {
          text: 'Name',
          value: 'name'
        },
        {
          text: 'Email',
          value: 'email'
        },

        {
          text: 'Address',
          value: 'city'
        },
        {
          text: 'Owners',
          value: 'owners'
        },
        {
          text: 'Show to Users',
          align: 'center',
          sortable: false,
          value: 'show_to_users'
        },
        {
          text: 'Main Branch',
          align: 'center',
          sortable: false,
          value: 'main_branch'
        },

        {
          text: '',
          value: 'actions'
        }
      ]
    };
  },
  computed: {
    ...mapState('franchise', ['franchise']),

    errorOccurred(): boolean {
      return this.errorMessage.length > 0;
    },

    screenWidth() {
      return window.innerWidth > 600;
    },

    filterFranchiseType(): Franchise[] {
      const filterFranchise = searchItems<Franchise>(
        this.franchise,
        this.search
      );

      return filterFranchise;
    }
  },

  methods: {
    ...mapActions({
      deleteFranchise: 'franchise/deleteFranchise',
      fetchFranchises: 'franchise/fetchFranchises',
      updateFranchise: 'franchise/updateFranchise'
    }),

    openDialog(item = undefined) {
      if (item) {
        (this as any).$refs.franchiseFormDialog.open(item, true);
      } else {
        (this as any).$refs.franchiseFormDialog.open();
      }
    },

    selectedFranchise(item: Franchise) {
      this.chosenFranchise = item;
      this.deleteFranchiseDialog = true;
    },

    clearAllErrors() {
      this.detailErrorMessage = '';
      this.errorMessage = '';
    },

    openFranchiseDetailDialog(franchise: Franchise) {
      (this.$refs.franchiseInformationDialog as any).open(franchise);
    },

    setFranchiseShowAllUsers(franchise: Franchise) {
      this.loading = true;

      franchise.owners = (franchise.owners as Account[]).map(
        (owner: number | Account) => {
          if (typeof owner == 'object') {
            return owner.pk;
          }
          return owner;
        }
      ) as number[];

      if (franchise.warehouses?.length) {
        franchise.warehouses = (franchise.warehouses as Warehouse[]).map(
          (warehouse: number | Warehouse) => {
            if (typeof warehouse == 'object') {
              return warehouse.pk;
            }
            return warehouse;
          }
        ) as number[];
      }

      this.updateFranchise(franchise)
        .catch((error: any) => {
          this.errorMessage = ErrorManager.extractApiError(error);
        })
        .finally(() => {
          this.loading = false;
        });
    },

    removeFranchise() {
      this.loading = true;

      this.deleteFranchise(this.chosenFranchise)
        .then(() => {
          this.setFranchiseData();
        })
        .catch((error: any) => {
          this.errorMessage = ErrorManager.extractApiError(error);
        })
        .finally(() => {
          this.loading = false;
          this.deleteFranchiseDialog = false;
        });
    },

    setFranchiseData() {
      this.loading = true;

      this.fetchFranchises()
        .catch((error: any) => {
          this.errorMessage = ErrorManager.extractApiError(error);
        })
        .finally(() => {
          this.loading = false;
        });
    }
  },

  async mounted() {
    this.setFranchiseData();
  }
});
