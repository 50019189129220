


























































































































































































import { ErrorManager } from '@/models/error';
import { FormRules } from '@/utils/formRules';
import { mapActions } from 'vuex';
import { cloneDeep } from 'lodash';
import auth from '@/mixins/auth';
import UserLookup from '../staffnotification/UserLookup.vue';
import { Franchise, Warehouse } from '@/models/franchise';
import { Account } from '@/models/account';

export default auth.extend({
  name: 'StaffFranchiseForm',

  components: { UserLookup },

  mixins: [FormRules],

  data() {
    return {
      dialog: false,
      valid: false,
      loading: false,
      editForm: false,

      errorMessage: '',
      franchise: new Franchise()
    };
  },

  computed: {
    errorOccurred(): boolean {
      return this.errorMessage.length > 0;
    }
  },

  methods: {
    ...mapActions({
      createFranchise: 'franchise/createFranchise',
      updateFranchise: 'franchise/updateFranchise'
    }),

    open(franchise = new Franchise(), editForm = false) {
      this.dialog = true;
      this.editForm = editForm;
      this.franchise = cloneDeep(franchise);
    },

    close() {
      (this.$refs.form as any).resetValidation();
      this.franchise = new Franchise();
      this.dialog = false;
      this.errorMessage = '';
    },

    addPhoneTextField() {
      this.franchise.phone_numbers?.push({ phone_number: '876' });
    },

    removePhoneTextField(index: number) {
      this.franchise.phone_numbers?.splice(index, 1);
    },

    addNewFranchise() {
      this.loading = true;

      this.createFranchise(this.franchise)
        .then(() => {
          this.$emit('setFranchiseData');
          this.close();
        })
        .catch((error: any) => {
          if (error.response.status == 400) {
            if (error.response.data.email) {
              this.errorMessage = error.response.data.email;
            } else {
              this.errorMessage = ErrorManager.extractApiError(error);
            }
          } else {
            this.errorMessage = ErrorManager.extractApiError(error);
          }
        })
        .finally(() => (this.loading = false));
    },

    editFranchise() {
      this.loading = true;

      this.franchise.owners = (this.franchise.owners as Account[]).map(
        (owner: number | Account) => {
          if (typeof owner == 'object') {
            return owner.pk;
          }
          return owner;
        }
      ) as number[];

      if (this.franchise.warehouses?.length) {
        this.franchise.warehouses = (this.franchise
          .warehouses as Warehouse[]).map((warehouse: number | Warehouse) => {
          if (typeof warehouse == 'object') {
            return warehouse.pk;
          }
          return warehouse;
        }) as number[];
      }
      this.updateFranchise(this.franchise)
        .then(() => {
          this.$emit('setFranchiseData');
          this.close();
        })
        .catch((error: any) => {
          this.errorMessage = ErrorManager.extractApiError(error);
        })
        .finally(() => (this.loading = false));
    }
  }
});
